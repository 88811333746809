.breadcrumbs{
    display: flex;
    align-items: center;
    padding: 40px 0px;
    @media only screen and (max-width : 768px){
        display: none;
    }
    li{
        margin-right: 20px;
        display: flex;
        align-items: center;
        white-space: nowrap;
        &:last-child{
            margin-right: 0;
            white-space: inherit;
        }
        @media only screen and (max-width : 1200px){
            margin-right: 12px;
            font-size: 14px;
        }
        a{
            padding-right: 40px;
            text-decoration: none;
            color: $color_dark;
            @media only screen and (max-width : 1200px){
                padding-right: 20px;
            }
        }
        .breadcrumbs_arrow{
            @media only screen and (max-width : 1200px){
                width: 9px;
            }
        }
    }
    .breadcrumbs_icon{
        display: none;
        svg{
            vertical-align: middle;
        }
        @media only screen and (max-width : 1200px){
            display: block;
        }
    }
    .breadcrumbs_text{
        @media only screen and (max-width : 1200px){
            display: none;
        }
    }
}
