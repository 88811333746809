.catalog{
    .section_title{
        margin-bottom: 40px;
    }
    .catalog_wrap{
        display: flex;
        flex-wrap: wrap;
        .catalog_item{
            padding: 0px 35px;
            flex: 0 0 33%;
            margin-bottom: 100px;
            .product_card{
                max-height: inherit;
            }
        }
    }
}

#product_page{
    padding-top: 40px;
    padding-bottom: 100px;
    .product_info_wrap{
        display: flex;
        flex-wrap: wrap;
        .product_image{
            flex: 0 0 50%;
            overflow: hidden;
            padding-right: 200px;
            .product_slider_for{
                margin-bottom: 20px;
            }
            .product_slider_nav{
                .swiper-slide{
                    padding: 0px 10px;
                }
            }
        }
        .product_info{
            flex: 0 0 50%;
            .video{
                margin-bottom: 40px;
                height: 376px;
                iframe{
                    border-radius: 20px;
                    width: 75%;
                    height: 100%;
                }
            }
        }
        .section_title{
            margin-bottom: 40px;
            text-transform: inherit;
        }
        .admin_text{
            margin-bottom: 40px;
            p{
                margin-bottom: 10px;
            }
        }
        .product_info_file{
            a{
                padding: 15px 30px;
                border: 1px solid $color_dark;
                border-radius: 10px;
                text-decoration: none;
                color: $color_dark;
                display: inline-block;
                span{
                    margin-left: 10px;
                    svg{
                        vertical-align: middle;
                    }
                }
            }
        }
    }
}
.product_slider_for img,
.product_slider_nav img {
    width: 100%;
    height: auto;
    cursor: pointer;
}
