header{
    background: $bg_color;
    .header_top{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 17px;
        padding-top: 40px;
        border-bottom: 2px solid $White;
        .logo{
            max-width: 297px;
            width: 100%;
            a{
                display: block;
            }
        }
        .header_email{
            a{
                text-decoration: none;
                color: $White;
            }
        }
        .header_btn{
            a{
                text-decoration: none;
                color: $White;
                padding: 11.5px 55px;
                border: 1px solid $White;
                border-radius: 10px;
                display: inline-block;
            }
        }
        .burger_menu_wrap{
            display: none;
            align-items: center;
            cursor: pointer;
            .burger_name{
                color: $White;
                padding-right: 30px;
            }
            .burger_menu{
                position: relative;
                width: 20px;
                height: 8px;
                span{
                    position: absolute;
                    top: 4px;
                    right: 0;
                    width: 20px;
                    height: 1px;
                    background: $White;
                    transition: all 0.3s ease-in-out;
                }
                &:before{
                    content: "";
                    position: absolute;
                    right: 0;
                    width: 10px;
                    height: 1px;
                    background: $White;
                    top: 0px;
                    transition: all 0.3s ease-in-out;
                }
                &:after{
                    content: "";
                    position: absolute;
                    left: 0;
                    bottom: 0px;
                    height: 1px;
                    width: 10px;
                    background: $White;
                    transition: all 0.3s ease-in-out;
                }
            }
            &.active{
                .burger_menu{
                    span{
                        transform: rotate(45deg);
                        transition: all 0.3s ease-in-out;
                        height: 1.5px;
                    }
                    &:after{
                        transform: rotate(-45deg);
                        transition: all 0.3s ease-in-out;
                        height: 1.5px;
                    }
                    &:before{
                        transform: rotate(-45deg);
                        transition: all 0.3s ease-in-out;
                        height: 1.5px;
                    }
                }
            }
        }
    }
    .header_nav{
        padding-top: 40px;
        padding-bottom: 37px;
        .header_nav_list{
            display: flex;
            align-items: center;
            justify-content: center;
            .header_nav_item{
                position: relative;
                padding: 0px 15px;
                font-size: 19px;
                &:first-child{
                    padding-left: 0;
                }
                &:last-child{
                    padding-right: 0;
                }
                a{
                    text-decoration: none;
                    color: $White;
                }
                .header_nav_arrow{
                    display: none;
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                }
            }
        }
    }
}
