@media only screen and (max-width : 1480px){
    #advantages{
        .advantages_card{
            justify-content: center;
            .advantages_card_item{
                margin-bottom: 20px;
                flex: 0 0 calc(40% - 33px);
                &:nth-child(2n+2){
                    margin-right: 0px;
                }
                &:nth-child(4n+4){
                    margin-right: 0px;
                }
                &:nth-child(3n+3){
                    margin-left: 0px;
                }
            }
        }
    }
}
@media only screen and (max-width : 1440px){
    .container_small{
        max-width: 1440px!important;
    }
    .product_card{
        padding: 20px;
        // height: auto!important;
    }
}
@media only screen and (max-width : 1400px){
    header{
        .header_top{
            padding-bottom: 40px;
        }
    }
    #about_the_company{
            padding-top: 60px;
    }
    #products{
        .products_scroll{
            .products_wrap{
                .products_item{
                    margin: 0px 12px;
                    &:nth-child(3n+1){
                        margin-top: 105px;
                    }
                }
            }
        }
    }
    .header{
        overflow: hidden;
        .header_top{
            border-bottom: none;
            .header_email{
                display: none;
            }
            .header_btn{
                display: none;
            }
            .burger_menu_wrap{
                display: flex;
            }
        }
        .header_nav{
            background: #c9a2a2;
            margin: 0px -999px;
            padding: 0px 999px;
            background: $White;
            display: none;
            .header_nav_list{
                display: block;
                padding-top: 60px;
                padding-bottom: 100px;
                .header_nav_item{
                    padding: 0;
                    a{
                        border-bottom: 2px solid #B6B6B6;
                        padding: 10px 0px;
                        color: $color_dark;
                        display: block;
                    }
                    .header_nav_arrow{
                        display: block;
                    }
                }
            }
        }
    }
    #video_block{
        .container{
            padding: 0;
            .video_block_wrap{
                padding: 40px;
                border-radius: 0px;
                .slider_arrow_left, .slider_arrow_right{
                    display: none!important;
                }
                .video_list{
                    padding: 0px;
                }
            }
        }
    }
}
@media only screen and (max-width : 1280px){
    #rehabilitation{
        .container{
            padding: 0px 40px;
        }
        .rehabilitation_wrap{
            background: transparent;
            overflow: inherit;
            .rehabilitation_img{
                border-radius: 25px;
                overflow: hidden;
                flex: 0 0 100%;
                height: 440px;
                order: 2;
            }
            .rehabilitation_info{
                padding: 0;
                flex: 0 0 100%;
                .rehabilitation_text{
                    margin-bottom: 30px;
                }
            }
        }
    }
}
@media only screen and (max-width : 1200px){
    #products {
        .products_scroll {
            .products_wrap{
                min-width: 1200px;
            }
        }
    }
    #product_page{
        .product_info_wrap{
            .product_image{
                flex: 0 0 100%;
                padding-right: 0px;
                .product_slider_nav{
                    margin-bottom: 40px;
                }
            }
            .product_info{
                flex: 0 0 100%;
                display: flex;
                flex-wrap: wrap;
                position: relative;
                .product_video{
                    flex: 0 0 90%;
                    order: 2;
                }
                .admin_text{
                    flex: 0 0 100%;
                    order: 1;
                }
                .product_info_file{
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    z-index: 10;
                }
            }
            .product_info{
                .video{
                    margin-bottom: 0;
                }
            }
        }
    }

    #advantages{
        .advantages_card{
            .advantages_card_item{
                flex: 0 0 calc(50% - 40px);
            }
        }
    }
    #about_the_company{
        padding-top: 60px;
        .about_company_wrap_all{
            display: flex;
            .about_company_wrap{
                padding-right: 40px;
                margin-bottom: 0;
                flex: 0 0 50%;
                .company_text_wrap{
                    display: block;
                }
                .anchor_btn{
                    position: static;
                    transform: none;
                    display: inline-block;
                    margin-top: 40px;
                    a{
                        box-shadow: 0 4px 10px 0 rgba(71, 74, 80, 1);
                        background: $bg_color;
                        color: $White;
                        text-decoration: none;
                        font-size: 20px;
                        padding: 12px 42px;
                        border-radius: 25px;
                        font-family: $open_sans_regular;
                        display: inline-block;
                    }
                }
                .about_company_title{
                    padding-top: 0;
                }
            }
            .about_company_img_wrap{
                .about_company_img{
                    height: 586px;
                    img{
                        height: 100%;
                    }
                }
            }
        }
    }
    footer{
        .logo{
            margin: 0;
            margin-bottom: 57px;
        }
        .footer_info{
            display: block;
            margin-bottom: 20px;
            .footer_info_item{
                border-bottom: 2px solid #FFFFFF;
                margin-bottom: 20px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
        .footer_bottom{
            text-align: left;
        }
    }
    #products{
        .container{
            padding: 0;
            .products_head{
                padding: 0px 20px;
            }
            .products_scroll{
                margin-left: -30px;
            }
        }
    }
    .catalog{
        .catalog_wrap{
            .catalog_item{
                padding: 0px 30px;
            }
        }
    }
}
@media only screen and (max-width : 1024px){
    .catalog{
        .catalog_wrap{
            .catalog_item{
                flex: 0 0 50%;
                margin-bottom: 60px;
            }
        }
    }
}

@media only screen and (max-width : 992px){
    #advantages{
        .advantages_card{
            .advantages_card_item{
                margin: 0px 10px;
                margin-bottom: 20px;
            }
        }
    }
}


@media only screen and (max-width : 768px){
    #aids{
            padding-bottom: 20px;
        .aids_head{
            .aids_arrow_wrap{
                display: none;
            }
        }
    }
    #product_page{
        .product_info_wrap{
            .product_info{
                .product_info_file{
                    position: static;
                    order: 3;
                    flex: 0 0 100%;
                    a{
                        width: 100%;
                        text-align: center;
                    }
                }
                .product_video{
                    margin-bottom: 40px;
                    flex: 0 0 100%;
                }
                .video{
                    iframe{
                        width: 100%;
                    }
                }
            }
        }
    }
    #advantages{
        margin-bottom: 40px;
        .advantages_card{
            .advantages_card_item{
                margin: 0;
                margin-bottom: 20px;
                flex: 0 0 55%;
                &:nth-child(odd){
                    margin-right: auto;
                }
                &:nth-child(even){
                    margin-left: auto;
                }
                .card_item_num{
                    font-size: 30px;
                }
            }
        }
    }
    .catalog{
        .catalog_wrap{
            .catalog_item{
                padding: 0px 10px;
                margin-bottom: 20px;
                .product_card{
                    padding: 20px 17px 18px 17px;
                    max-height: 400px;
                    .product_card_title{
                        text-align: center;
                        font-size: 12px;
                        margin-bottom: 15px;
                    }
                    .card_arrow{
                        display: none;
                    }
                }
            }
        }
        .section_title{
            margin-bottom: 20px;
            margin-top: 40px;
        }
    }
    #aids{
        .slider_wrap{
            margin-right: 0;
        }
    }
    .header{
        .header_nav{
            .header_nav_list{
                padding-top: 26px;
            }
        }
    }
    #rehabilitation{
        .container{
            padding: 0px 20px;
        }
        padding-bottom: 30px;
        .rehabilitation_wrap{
            .rehabilitation_img{
                height: 200px;
            }
            .rehabilitation_info{
                .rehabilitation_title{
                    margin-bottom: 10px;
                }
                .rehabilitation_text{
                    margin-bottom: 20px;
                    p{
                        margin-bottom: 5px
                    }
                }
            }
        }
    }
    .header{
        .header_top{
            padding: 20px 0px;
            .logo{
                max-width: 150px;
                width: 100%;
            }
        }
    }
    #video_block{
        margin-bottom: 40px;
        .container{
            .video_block_wrap{
                display: block;
                padding: 20px;
                .video_list_wrap{
                    width: 100%;
                    margin-bottom: 20px;
                }
                .video_block_btn{
                    width: 100%;
                    text-align: center;
                    color: $bg_color;
                    font-family: $open_sans_bold;
                }
                .video_block_text{
                    .section_title{
                        margin-bottom: 10px;
                    }
                    .video_block_info{
                        margin-bottom: 20px;
                        p{
                            margin-bottom: 5px;
                        }
                    }
                }
            }
        }
    }
    .slider_wrap{
        .slick-slide.slick-active{
            max-width: inherit!important;
        }
        .slick-slide.slick-active + div{
            max-width: inherit!important;
        }
    }
    #about_the_company{
        padding-top: 40px;
        padding-bottom: 40px;
        .bg_decor_top_mob{
            display: block;
            width: 100%;
        }
        .bg_decor_top{
            display: none;
        }
        .about_company_wrap_all{
            .about_company_wrap{
                flex: 0 0 100%;
                padding-right: 0px;
                .anchor_btn{
                    display: block;
                    margin-top: 0;
                    a{
                        width: 100%;
                        padding: 14px 42px;
                        text-align: center;
                    }
                }
                .about_company_text{
                    p{
                        margin-bottom: 5px;
                    }
                }
            }
            .about_company_img_wrap{
                flex: 0 0 100%;
                .about_company_img{
                    height: 320px;
                }
            }
        }
        .about_company_img{
            margin-bottom: 20px;
            img{
                width: 100%;
                height: auto;
            }
        }
        .about_company_text{
            margin-bottom: 20px;
        }
    }
    #ways {
        margin-bottom: 40px;
        .ways_title{
            margin-bottom: 20px;
        }
    }
    #products{
        margin-bottom: 40px;
        .products_head{
            .section_subtitle{
                p{
                    margin-bottom: 5px;
                }
            }
        }
        .products_scroll{
            .products_wrap{
                min-width: 992px;
                .products_item{
                    margin: 0px 5px;
                }
            }
        }
    }
    footer{
        .logo{
            margin-bottom: 20px;
        }
        .footer_info{
            margin-bottom: 10px;
            .footer_info_item{
                margin-bottom: 10px;
                .footer_connect{
                    li{
                        margin-bottom: 10px;
                    }
                }
                .footer_item_title{
                    margin-bottom: 10px;
                }
            }
        }
        .footer_adress{
            padding-bottom: 10px;
        }
        .footer_bottom{
            padding-top: 10px;
        }
    }
}
@media only screen and (max-width : 440px){
    .catalog{
        .catalog_wrap{
            .catalog_item{
                    flex: 0 0 100%;
            }
        }
    }
}
@media only screen and (max-width : 420px){
    #advantages{
        .advantages_card{
            .advantages_card_item{
                flex: 0 0 75%;
                .card_item_text{
                    min-width: auto;
                }
            }
        }
    }
}
