@font-face {
    font-family: 'open_sans_bold';
    src: url('../fonts/open_sans_bold.eot');
    src: url('../fonts/open_sans_bold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/open_sans_bold.woff2') format('woff2'),
         url('../fonts/open_sans_bold.woff') format('woff'),
         url('../fonts/open_sans_bold.ttf') format('truetype'),
         url('../fonts/open_sans_bold.svg#open_sans_bold') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'open_sans_regular';
    src: url('../fonts/open_sans_regular.eot');
    src: url('../fonts/open_sans_regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/open_sans_regular.woff2') format('woff2'),
         url('../fonts/open_sans_regular.woff') format('woff'),
         url('../fonts/open_sans_regular.ttf') format('truetype'),
         url('../fonts/open_sans_regular.svg#open_sans_regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
