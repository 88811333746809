#about_the_company{
    padding-top: 80px;
    padding-bottom: 100px;
    .bg_decor_top{
        position: absolute;
        top: 0;
        max-width: 100%;
        z-index: -1;
        left: 50%;
        transform: translateX(-50%);
    }
    .bg_decor_top_mob{
        display: none;
        position: absolute;
        top: 0;
        max-width: 100%;
        z-index: -1;
        left: 50%;
        transform: translateX(-50%);
    }
    .about_company_wrap{
        position: relative;
        margin-bottom: 37px;
        .about_company_title{
            padding-bottom: 10px;
            padding-top: 21px;
        }
        .company_text_wrap{
            display: flex;
            justify-content: space-between;
            .about_company_text{
                flex: 0 0 36%;
                p{
                    margin-bottom: 10px;
                }
            }
        }
    }
    .about_company_img_wrap{
        position: relative;
        flex: 0 0 50%;
        .about_company_img{
            border-radius: 40px;
            overflow: hidden;
            height: 506px;
            img{
                width: 100%;
                height: auto;
            }
        }
        .anchor_btn{
            position: absolute;
            bottom: 90%;
            left: 50%;
            transform: translateX(-50%);
            a{
                box-shadow: 0 4px 10px 0 rgba(71, 74, 80, 1);
                background: $bg_color;
                color: $White;
                text-decoration: none;
                font-size: 30px;
                font-family: $open_sans_bold;
                padding: 130px 55px;
                border-radius: 50%;
                display: inline-block;
            }
        }
    }
}

// ------------------------------

#advantages{
    margin-bottom: 140px;
    .advantages_title{
        margin-bottom: 40px;
    }
    .advantages_card{
        font-family: $open_sans_bold;
        display: flex;
        flex-wrap: wrap;
        text-transform: uppercase;
        min-height: 300px;
        align-items: flex-start;
        .advantages_card_item{
            box-shadow: 0 4px 10px 0 rgba(71, 74, 80, 1);
            border-radius: 25px;
            padding: 5px 12px 20px 20px;
            flex: 0 0 calc(25% - 40px);
            margin: 0px 20px;
            display: flex;
            flex-direction: column;
            transition: 0.5s;
            &:hover{
                transform: scale(1.07);
                transition: 0.5s;
            }
            &:nth-child(4n+1){
                margin-left: 0px;
            }
            &:nth-child(4n+4){
                margin-right: 0px;
            }
            .card_item_num{
                font-size: 60px;
                flex-grow: 1;
                margin-bottom: 10px;
            }
            .card_item_text{
                display: flex;
                align-items: flex-end;
                // min-width: 300px;
            }
            &:nth-child(2n){
                background: $bg_color;
                color: $White;
                align-self: flex-end;
            }
        }
    }
}


// ------------------------------

#aids{
    overflow: hidden;
    .aids_decor{
        position: absolute;
        bottom: 40px;
        z-index: -1;
        max-width: 100%;
        left: 50%;
        transform: translateX(-50%);
    }
    .aids_head{
        position: relative;
        .aids_arrow_wrap{
            display: flex;
            align-items: center;
            position: absolute;
            right: 0;
            top: 0;
            .aids_arrow_left,.aids_arrow_right{
                cursor: pointer;
                &:hover{
                    svg{
                        circle{
                            fill: white;
                        }
                        path{
                            fill: #374E48;
                        }
                    }
                }
            }
            .aids_arrow_left{
                margin-right: 5px;
            }
            .aids_arrow_right{
                margin-left: 5px;
            }
        }
        .section_title{
            position: relative;
        }
    }
    .slider_wrap{
        margin-right: -90px;
        .slider_item{
            padding: 0px 25px;
            width: auto!important;
        }
        .slick-track{
            padding: 40px 0px;
        }
        .slick-slide{
            text-align: center;
        }
        .slick-slide.slick-active{
            max-width: 510px;
        }
        .slick-slide.slick-active + div{
            max-width: 510px;
        }

    }
}
.product_card{
    box-shadow: 0 4px 10px 0 rgb(71, 74, 80);
    border-radius: 25px;
    padding: 20px 16px 59px 20px;
    background: $White;
    position: relative;
    max-height: 545px;
    // max-height: 560px;
    a{
        display: block;
        text-decoration: none;
        color: $color_dark;
        height: 100%;
    }
    .product_card_title{
        margin-bottom: 20px;
        text-transform: uppercase;
    }
    img{
        width: 100%;
        height: auto;
    }
    .card_arrow{
        text-align: right;
        opacity: 0;
        transition: 0.5s;
        position: absolute;
        right: 20px;
        bottom: 10px;
    }
    &:hover{
        .card_arrow{
            opacity: 1;
            transition: 0.5s;
        }
        transform: scale(1.07);
        transition: 0.5s;
    }
    transition: 0.5s;
}
// ------------------------------

#video_block{
    margin-bottom: 80px;
    padding-top: 90px;
    .video_block_decor{
        position: absolute;
        top: -41px;
        z-index: -1;
        max-width: 100%;
        left: 50%;
        transform: translateX(-50%);
    }
    .video_block_wrap{
        background: $bg_color;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 40px;
        padding: 64px 90px 64px 100px;
        .video_block_text{
            color: $White;
            flex: 0 0 40%;
            .section_title{
                margin-bottom: 20px;
            }
            .video_block_info{
                margin-bottom: 40px;
                p{
                    margin-bottom: 10px;
                }
            }
        }
        .video_list_wrap{
            width: 50%;
            position: relative;
            .video_list{
                padding: 0px 70px;
                .video_list_item{
                    .video{
                        height: 376px;
                        iframe{
                            border-radius: 20px;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }
        .video_block_btn{
            text-decoration: none;
            color: $color_dark;
            padding: 12px 40px;
            background: $White;
            border-radius: 20px;
            display: inline-block;
        }
    }
    .slider_arrow_left{
        position: absolute;
        top: 50%;
        left: 0;
        z-index: 10;
        transform: translateY(-50%);
        cursor: pointer;
        svg{
            transition: 0.5s;
        }
        &:hover{
            svg{
                transform: scale(1.2);
                transition: 0.5s;
            }
        }
    }
    .slider_arrow_right{
        position: absolute;
        top: 50%;
        right: 0;
        z-index: 10;
        transform: translateY(-50%);
        cursor: pointer;
        svg{
            transition: 0.5s;
        }
        &:hover{
            svg{
                transform: scale(1.2);
                transition: 0.5s;
            }
        }
    }
}

// ------------------------------

#ways{
    margin-bottom: 80px;
    .ways_title{
        margin-bottom: 40px;
    }
    .accordion_wrapper{
        max-width: 1200px;
        .accordion_item{
            .accordion_item_head{
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px 0px;
                border-bottom: 2px solid #B6B6B6;
                .accordion_item_arrow{
                    cursor: pointer;
                    transition: 0.5s;
                }
                .accordion_btn{
                    background: none;
                    border: none;
                    text-transform: uppercase;
                    padding: 0;
                }
            }
            .accordion_content{
                display: none;
                padding-top: 10px;
            }
        }
    }
}

// ------------------------------

#products{
    margin-bottom: 100px;
    .products_head{
        margin-bottom: 40px;
        .section_subtitle{
                max-width: 1200px;
            p{
                margin-bottom: 10px;
            }
        }
    }
    .products_scroll::-webkit-scrollbar {
        width: 0.5em;
    }

    .products_scroll::-webkit-scrollbar-thumb {
        background-color: transparent;
    }

    .products_scroll::-webkit-scrollbar-track {
        background-color: transparent;
    }
    .products_scroll{
        overflow: auto;
        padding-bottom: 30px;
        .products_wrap{
            display: flex;
            justify-content: space-between;
            .products_item{
                margin: 0px 25px;
                &:first-child{
                    margin-left: 0;
                }
                &:last-child{
                    margin-right: 0;
                }
                &:nth-child(3n +1){
                    margin-top: 135px;
                }
                &:nth-child(5n){
                    margin-top: 30px;
                }
                a{
                    display: block;
                    img{
                        width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }
}

// ------------------------------

#rehabilitation{
    padding-bottom: 100px;
    position: relative;
    .rehabilitation_wrap{
        display: flex;
        align-items: stretch;
        border-radius: 40px;
        overflow: hidden;
        background: $White;
        flex-wrap: wrap;
        .rehabilitation_info{
            padding: 124px 60px;
            flex: 0 0 40%;
            .rehabilitation_title{
                margin-bottom: 20px;
            }
            .rehabilitation_text{
                p{
                    margin-bottom: 10px;
                }
            }
        }
        .rehabilitation_img{
            flex: 0 0 60%;
            img{
                width: 100%;
                height: 100%;
            }
        }
    }
    .bg_decor_bottom{
        position: absolute;
        bottom: 0;
        z-index: -1;
        max-width: 100%;
        left: 50%;
        transform: translateX(-50%);
    }
}
