footer{
    background: $bg_color;
    .logo{
        max-width: 320px;
        width: 100%;
        margin: auto;
        padding-top: 40px;
        margin-bottom: 40px;
        a{
            display: block;
        }
    }
    .footer_info{
        display: flex;
        justify-content: space-between;
        margin-bottom: 37px;
        .footer_info_item{
            color: $White;
            .footer_item_title{
                margin-bottom: 20px;
            }
            .footer_connect{
                li{
                    margin-bottom: 20px;
                    a{
                        text-decoration: none;
                        color: $White;
                    }
                }
            }
        }
    }
    .footer_adress{
        color: $White;
        padding-bottom: 17px;
    }
    .footer_bottom{
        padding-top: 21px;
        padding-bottom: 36px;
        color: $White;
        text-align: center;
        font-size: 15px;
        border-top: 2px solid $White;
    }
}
