@import 'node_modules/slick-carousel/slick/slick.scss';

@import 'variables';
@import 'reset';
@import 'fonts';

@import 'header';
@import 'footer';
@import 'main';
@import 'catalog';
@import 'pagination';
@import 'media';

@import "~lightgallery/scss/lightgallery";
@import '../../node_modules/wowjs/css/libs/animate.css';

*{
    box-sizing: border-box;
}
body{
    color: $color_dark;
    font-family: $open_sans_regular;
    font-size: $font-size-base;
    line-height: $line-height-base;
    margin: 0;
    padding: 0;
    @media only screen and (max-width : 768px){
        font-size: $font-size-base_mibile;
    }
}
.container{
    width: 100%;
    max-width: 1750px;
    padding: 0px 40px;
    margin: auto;
    @media only screen and (max-width : 768px){
        padding: 0px 20px;
    }
}
.container_small{
    width: 100%;
    max-width: 1385px;
    padding: 0px 40px;
    margin: auto;
    @media only screen and (max-width : 768px){
        padding: 0px 20px;
    }
}
.section_title{
    font-family: $open_sans_bold;
    font-size: 30px;
    text-transform: uppercase;
    @media only screen and (max-width : 768px){
        font-size: 20px;
    }
}
.section_subtitle{
    margin-top: 10px;
}
.rotate{
    transform: rotate(180deg);
    transition: 0.5s;
}
.main_arrow_top {
    position: fixed;
    display: block;
    top: 80%;
    right: 2%;
    z-index: 10;
    cursor: pointer;
    &:hover{
        svg{
            circle{
                fill: $White;
            }
            path{
                fill: $color_dark;
            }
        }
    }
}
.bg_wrap_bottom{
    position: relative;
}
.bg_wrap_top{
    position: relative;
}
